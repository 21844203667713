<template>
  <div class="hl-carousel">
    <div class="hl-carousel-list">
      <div v-for="(item, i) in carousellist" :key="i" class="carousel-item" :class="{active: activeIndex === i}">
        <div class="carousel-item-content area-wrapper">
          <div class="desc">
            <h2>{{item.title}}</h2>
            <div class="desc-text">
              <p>{{item.text1}}</p>
              <p v-if="item.text2">{{item.text2}}</p>
            </div>
            <div class="btn-wrapper">
              <div class="btn">
                <a v-if="i === 0" href="javascript:void(0)" @click="freeTry()">{{item.btnText}}</a>
                <a :href="item.btnLink" target="_blank" v-if="i === 1">{{item.btnText}}</a>
              </div>
              <div v-if="i===0" class="btn-ppt"><a href="www.dadaex.cn/api/static/upload/EB2.0PPT.pptx" target="_blank">视频演示</a></div>
            </div>
          </div>
          <div class="img-banner">
          </div>
        </div>
      </div>
    </div>
    <ul class="slick-dots">
      <li 
        v-for="(item, i) in carousellist"
        :key="i"
        class="dot"
        :class="{active: activeIndex === i}"
        @click="handleDotClick(i)"
      >
        <span></span>
      </li>
    </ul>
    <div class="freePopup" v-show="showPop">
      <div class="freePopupMain">
        <div class="freePopupTit">
          <h2>试用登录 <i class="el-icon-close" @click="closefreePopup"></i></h2>
        </div>
        <div class="freePopupCont">
          <div class="freePopupItem">
            <el-input v-model="number" placeholder="请输入手机号"></el-input>
          </div>
          <div class="freePopupItem">
            <el-row :gutter="20">
              <el-col :span="16">
                <el-input v-model="captcha" placeholder="请输入手机验证码" maxlength="6"></el-input>
              </el-col>
              <el-col :span="8">
                <el-button type="success" v-show="!sendCode" @click="toSendCode()">获取验证码</el-button>
                <p v-show="sendCode">重新发送({{count}})s</p>
              </el-col>
            </el-row>
          </div>
          <div class="freePopupBtn">
             <el-button type="warning" @click="loginNow()">立即登录</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      timer: null,
      activeIndex: 0,
      carousellist: [
         {
          title: "关于恒链",
          text1: "一站式物流SaaS服务提供商，提供在线订舱、舱单发送、智能客服、视频会议等服务，实现IPA智能业务流程自动化，全面提升员工工作效率，节省办公成本。",
          btnText: "免费试用",
        },
         {
          title: "报价查询",
          text1: "平台货代联盟运价查询，支持海量船公司和船代运价查询，显示30天内最低报价，为平台用户吸引大量客户。",
          btnText: "查看详情",
          btnLink: "http://test.sfyf.cn/booking/newEbooking.html"
        },
      ],
      number:'',
      captcha:'',
      checkToken:'',
      showPop:false,
      sendCode:false,
      count:60
    };
  },
  mounted() {
    this.AutoBroadCast();
  },
  methods: {
    AutoBroadCast() {
      this.timer && clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.setNextActiveIndex();
      }, 8000);
    },
    handleDotClick(i) {
      this.activeIndex = i;
      this.AutoBroadCast();
    },
    setNextActiveIndex() {
      let index = this.activeIndex;
      if (index >= this.carousellist.length - 1) {
        this.activeIndex = 0;
      } else {
        ++this.activeIndex;
      }
    },
    // 点击“免费试用”
    freeTry(){
      this.showPop = true
    },
    closefreePopup(){
      this.showPop = false
      this.number=''
      this.captcha=''
      this.checkToken=''
    },
     // 验证手机号
    checkPhone(val) {
        var myreg = /(^1|^84)(\d{10}|\d{9})$/;
        if(!myreg.test(val)) {
            return false
        } else {
            return true
        }
    },
    // 发送验证码
    toSendCode(){
      if(!this.number) {
        this.$message({
          type: 'warning',
          message: "请输入手机号",
          center:true,
          showClose: true,
        });
      }else if(!this.checkPhone(this.number)){
         this.$message({
          type: 'warning',
          message: "请输入正确格式的手机号",
          center:true,
          showClose: true,
        });
      }else {
        axios.get(this.GLOBAL.BASE_URL3+'/user/captchas',{
          params:{
            number:this.number
          }
        })
        .then (res=>{
          if(res.data.token){
            this.checkToken = res.data.token
            this.sendCode = true
            var countTimer = setInterval(()=>{
              this.count --
              if(this.count === 0) {
                clearInterval(countTimer)
                this.sendCode = false
                this.count = 60
              }
            },1000) 
          }else {
            this.$message({
              type: 'error',
              message: res.data.message,
              center:true,
              showClose: true,
            });
          }
        })
        .catch(error=> {
          console.log(error);
          this.$message({
            type: 'error',
            message: error.errors,
            center:true,
            showClose: true,
          });
        })
      }
      
    },
    loginNow(){
      if(!this.number) {
        this.$message({
          type: 'warning',
          message: "请输入手机号",
          center:true,
          showClose: true,
        });
      }else if(!this.checkPhone(this.number)){
         this.$message({
          type: 'warning',
          message: "请输入正确格式的手机号",
          center:true,
          showClose: true,
        });
      }else if(!this.captcha){
         this.$message({
          type: 'warning',
          message: "请输入验证码",
          center:true,
          showClose: true,
        });
      } else if(!this.checkToken){
        this.$message({
          type: 'warning',
          message: "请重新获取验证码",
          center:true,
          showClose: true,
        });
      }else {
        axios.defaults.headers.common['Authorization'] = this.checkToken
        axios.get(this.GLOBAL.BASE_URL3+'/user/login/mobiles',{
          params:{
            number:this.number,
            captcha:this.captcha
          }
        })
        .then(res=>{
          if(res.data.token){
            var url = ''
            window.location.host == 'www.dadaex.cn' ? url = 'http://ebsimple.dadaex.cn/' : url = 'http://localhost:8001/'
            window.open(url+'user/login?token='+ res.data.token)
            this.closefreePopup()
          }else {
            this.$message({
              type: 'error',
              message: res.data.message,
              center:true,
              showClose: true,
            });
          }
        })
        .catch (error=>{
          console.log(error);
          this.$message({
            type: 'error',
            message: error.errors,
            center:true,
            showClose: true,
          });
        })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../style/mixin.scss";
@keyframes progress {
  0% {
    width: 5%;
  }
  100% {
    width: 100%;
  }
}
.hl-carousel {
  position: relative;
  .hl-carousel-list {
    position: relative;
    height: 540px;
    .carousel-item {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: none;
      background: #fff;
      &.active {
        display: block;
      }

      &:nth-child(1) {
        background: url("../../public/static/img/banner_bg_01.png") no-repeat;
        .img-banner {
          width: 518px;
          height: 420px;
          background: url("../../public/static/img/banner_01.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      &:nth-child(2) {
        background: url("../../public/static/img/banner_bg_02.png") no-repeat;
        .img-banner {
          width: 494px;
          height: 420px;
          background: url("../../public/static/img/banner_02.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    .carousel-item-content {
      display: flex;
      height: 100%;
      justify-content: space-between;
      align-items: center;
      
      .desc {
        align-self: center;
        width: 440px;
        h2 {
          font-size: 36px;
          color: #ef7609;
          margin-bottom: 30px;
        }
        .desc-text {
          font-size: 18px;
          margin-bottom: 30px;
          line-height: 36px;
          color: #505050;
        }

        .btn {
          display: inline-block;
          width: 151px;
          height: 43px;
          background: url("../../public/static/img/btn_bg.png") no-repeat;
          background-size: 100% 100%;
          margin-right: 20px;
          cursor: pointer;
          font-size: 13px;
          letter-spacing: 2px;
          text-align: center;
          font-weight: 700;
          line-height: 43px;
          color: #fff;
          a {
            color: #fff;
            display: inline-block;
            width: 100%;
            height: 100%;
          }
        }

        .btn-ppt {
          display: inline-block;
           width: 151px;
          height: 43px;
          background: url("../../public/static/img/btn_ppt.png") no-repeat;
          background-size: 100% 100%;
          cursor: pointer;
          font-size: 13px;
          letter-spacing: 2px;
          text-align: center;
          font-weight: 700;
          line-height: 43px;
          color: #fff;
          a {
            color: #fff;
          }
        }
      }
      .img {
        height: 300px;
        width: 260px;
        background: #ebb624;
      }
    }
  }
  .slick-dots {
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
    z-index: 1;
    display: flex;
    .dot {
      width: 40px;
      height: 16px;
      margin-right: 10px;
      cursor: pointer;
      span {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        height: 3px;
        background: #f3dfa7;
        cursor: pointer;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          display: inline-block;
          height: 100%;
          width: 0;
          background: #ef7609;
        }
      }

      &.active {
        span {
          &::before {
            animation: progress 8s linear;
          }
        }
      }
    }
  }
  .freePopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    background: rgba(0,0,0,.5);
    z-index: 9;
    .freePopupMain {
      width: 400px;
      height: auto;
      padding: 25px;
      background: #fff;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      border-radius: 5px;
      .freePopupTit {
        padding: 10px 0 15px;
        h2 {
          position: relative;
          font-size: 25px;
          i {
            position: absolute;
            top: -10px;
            right: 0;
          }
        }
      }
      .freePopupCont {
        padding: 10px;
        .freePopupItem {
          margin-bottom: 30px;
          .el-input{
            .el-input__inner:focus {
              border-color: #ef7609 !important;
            }
          }
          p {
            color: #999;
            line-height: 40px;
          }
          .el-button {
            padding: 12px 10px;
          }
        }
        .freePopupBtn {
          .el-button {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>