<template>
  <div class="carousel" @touchmove='touchMove' >
    <div class="carousel-item" :class="{active: activeIndex === 0}">
      <img src="../../public/static/img/banner_m_01.png" alt="">
      <div class="carousel-item-desc">
        <h2>关于恒链</h2>
        <div class="text">
          一站式物流SaaS服务提供商，提供在线订舱、舱单发送、智能客服、视频会议等服务，实现IPA智能业务流程自动化，全面提升员工工作效率，节省办公成本。
        </div>
        <div>
          <a class="btn btn1" href="http://www.dadaex.cn/admin/user/register" target="_blank" >免费试用</a>
          <a class="btn btn2" href="http://www.dadaex.cn/api/static/upload/EB2.0PPT.pptx" target="_blank" >演示视频</a>
        </div>
      </div>
    </div>
     <div class="carousel-item" :class="{active: activeIndex === 1}" >
      <img src="../../public/static/img/banner_m_02.png" alt="">
      <div class="carousel-item-desc">
        <h2>报价查询</h2>
        <div class="text">
          平台货代联盟运价查询，支持海量船公司和船代运价查询，显示30天内最低报价，为平台用户吸引大量客户。
        </div>
        <a class="btn btn1" href="http://test.sfyf.cn/booking/newEbooking.html" target="_blank" >查看详情</a>
      </div>
    </div>
    <ul class="slick-dots">
      <li 
        class="dot"
        :class="{active: activeIndex === 0}"
        @click="handleDotClick(0)"
      >
        <span></span>
      </li>
       <li 
        class="dot"
        :class="{active: activeIndex === 1}"
        @click="handleDotClick(1)"
      >
        <span></span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeIndex: 0
    }
  },
  mounted() {
    this.AutoBroadCast();
  },
  methods: {
    touchMove(e) {
      console.log('touchMove', e.touches)
    },
     AutoBroadCast() {
      this.timer && clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.setNextActiveIndex();
      }, 8000);
    },
    handleDotClick(i) {
      this.activeIndex = i;
      this.AutoBroadCast();
    },
    setNextActiveIndex() {
      let index = this.activeIndex;
      if (index >= 1) {
        this.activeIndex = 0;
      } else {
        ++this.activeIndex;
      }
    }
  }
}
</script>


<style lang="scss" scoped>

@keyframes progress {
  0% {
    width: 5%;
  }
  100% {
    width: 100%;
  }
}

.carousel {
  position: relative;
  .carousel-item {
    position: relative;
    display: none;
    &.active {
      display: block;
    }
    h2 {
      font-size: 28px;
      line-height: 39px;
      margin-bottom: 20px;
      color: #ef7609;
    }
    .text {
      font-size: 18px;
      margin-bottom: 30px;
      line-height: 36px;
      color: #5e2905;
    }
    img {
      width: 100%;
    }
    .carousel-item-desc {
      padding: 0 20px;
    }
    .carousel-item-desc {
      position: absolute;
      bottom: 68px;
    }
    .btn {
      display: inline-block;
      // width: 151px;
      // height: 43px;
       width: 102px;
      height: 33px;
      background: url("../../public/static/img/btn_bg.png") no-repeat;
      background-size: 100% 100%;
      color: #fff;
      line-height: 33px;
      text-align: center;
      &.btn1 {
        background: url("../../public/static/img/mobile/btn_01.png") no-repeat;
        background-size: 100% 100%;
        margin-right: 16px;
      }
      &.btn2 {
        background: url("../../public/static/img/mobile/btn_02.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .slick-dots {
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
    z-index: 1;
    display: flex;
    .dot {
      width: 40px;
      height: 16px;
      margin-right: 10px;
      cursor: pointer;
      span {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        height: 3px;
        background: #f3dfa7;
        cursor: pointer;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          display: inline-block;
          height: 100%;
          width: 0;
          background: #ebb624;
        }
      }

      &.active {
        span {
          &::before {
            animation: progress 8s linear;
          }
        }
      }
    }
  }
}
</style>

