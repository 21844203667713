<template>
  <div class="card-item" >
     <div class="top">
       <div class="logo">
         <img :src="logo" alt="">
         <span>{{title}}</span>
       </div>
       <img src="../../../../public//static/img/card/arrow.png" class="icon-arrow" alt="" />
     </div>
     <div class="line"></div>
     <div class="bottom">
       {{desc}}
     </div>
  </div>
</template>

<script>
export default {
  props: {
    logo: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    }
  }
}
</script>


<style lang="scss" scoped>
.card-item {
  background: url("../../../../public/static/img/card/card_bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 10px 22px;
  margin: 0 auto;
  margin-bottom: 20px;
  .top {
    border-bottom: 1px solid #ddd;
    font-size: 20px;
    position: relative;
    .logo {
      display: flex;
      align-items: center;
      height: 80px;
      color: #5e2905;
      img {
        width: 24px;
        margin-right: 10px;
        margin-left: 4px;
      }
    }
    .icon-arrow {
      width: 20px;
      height: auto;
      position: absolute;
      right: 0px;
      top: 30px;
    }
  }
  .bottom {
    min-height: 72px;
    padding: 20px 0;
    line-height: 22px;
    font-size: 14px;
     color: #823c0d;
  }
}
</style>

