<template>
  <div class="page-home page">
      <Carousel class="carousel-custom"></Carousel>
      <div class="products">
        <div class="area-wrapper">
          <h2 class="area-title">产品操作可视化 一键订舱</h2>
          <ul class="pro-list">
            <li @click.stop="onSelectedProChange(0)"  @mouseenter="activeProIndex=0" >
              <span class="text">报价查询</span>
              <img src="../../../public/static/img/pro_normal_01.png" alt="" class="img-normal" >
              <img src="../../../public/static/img/pro_active_01.png" alt="" class="img-active">
            </li>
            <li  @click.stop="onSelectedProChange(1)" @mouseenter="activeProIndex=1" >
              <span class="text">在线订舱</span>
              <img src="../../../public/static/img/pro_normal_02.png" alt="" class="img-normal" >
              <img src="../../../public/static/img/pro_active_02.png" alt="" class="img-active">
            </li>
            <li  @click.stop="onSelectedProChange(2)"  @mouseenter="activeProIndex=2" >
              <span class="text">舱单发送</span>
              <img src="../../../public/static/img/pro_normal_03.png" alt="" class="img-normal" >
              <img src="../../../public/static/img/pro_active_03.png" alt="" class="img-active">
            </li>
            <li  @click.stop="onSelectedProChange(3)"  @mouseenter="activeProIndex=3" >
              <span class="text">票证防伪</span>
              <img src="../../../public/static/img/pro_normal_04.png" alt="" class="img-normal" >
              <img src="../../../public/static/img/pro_active_04.png" alt="" class="img-active">
            </li>
            <li  @click.stop="onSelectedProChange(4)"  @mouseenter="activeProIndex=4" >
              <span class="text">智能客服</span>
              <img src="../../../public/static/img/pro_normal_05.png" alt="" class="img-normal" >
              <img src="../../../public/static/img/pro_active_05.png" alt="" class="img-active">
            </li>
          </ul>
          <div class="pro-selected">
            <a class="pro-selected-item" :class="{active: activeProIndex === 0}">
              <div class="text">
                实时查询海量船司船代30天最低报价，<br />
                支持最快，直达等多条件查询
              </div>
              <img src="../../../public/static/img/pro_active_bg_01.png" alt="">
            </a>
            <a class="pro-selected-item" :class="{active: activeProIndex === 1}" >
              <div class="text">
                无需专业培训和重复数据输入，统一界面，<br />
                轻松对接所有船司，实时订舱
              </div>
              <img src="../../../public/static/img/pro_active_bg_02.png" alt="">
            </a>
            <a class="pro-selected-item" :class="{active: activeProIndex === 2}">
              <div class="text">
                智能加人工双重审核保证，无需二次确认，<br />
                支持海量船司多家船代
              </div>
              <img src="../../../public/static/img/pro_active_bg_03.png" alt="">
            </a>
            <a class="pro-selected-item" :class="{active: activeProIndex === 3}">
              <div class="text">
                量子云码，破坏率90%依然准确识别，<br />
                完美超越普通二维码
              </div>
              <img src="../../../public/static/img/pro_active_bg_04.png" alt="">
            </a>
            <a class="pro-selected-item" :class="{active: activeProIndex === 4}">
              <div class="text">
                机器人智能回复用户咨询问题，人工客服与<br />
                机器人之间轻松切换，相辅相成更高效
              </div>
              <img src="../../../public/static/img/pro_active_bg_05.png" alt="">
            </a>
          </div>
        </div>
      </div>

      <div class="why-us area-wrapper">
        <h2 class="area-title">为什么选择恒链</h2>
        <div class="why-us-list">
          <div class="why-us-item">
          <img src="../../../public/static/img/why_us_01.png" alt="">
          <h4>IPA全流程自动化</h4>
          <p>人工智能和自动化助力企业</p>
        </div>
        <div class="why-us-item">
          <img src="../../../public/static/img/why_us_02.png" alt="">
          <h4>节省人力成本70%</h4>
          <p>复杂操作简单化，避免重复劳动</p>
        </div>
        <div class="why-us-item">
          <img src="../../../public/static/img/why_us_03.png" alt="">
          <h4>数据错误率为0</h4>
          <p>资料分类存储，流程操作可视化 </p>
        </div>
        <div class="why-us-item">
          <img src="../../../public/static/img/why_us_04.png" alt="">
          <h4>提升工作效率</h4>
          <p>一键订舱，操作简单高效</p>
        </div>
        </div>
        
      </div>
      <div class="client-say">
        <h2 class="area-title">客户这么说</h2>
        <div class="client-say-content">
          <div class="say say-01">
            <p class="p1"><span>上海泛远</span><span>黎女士</span></p>
            <p class="p2">
              非常方便，每天都在使用，<br/>极大的提升了工作效率，<br/>系统太棒了
            </p>
          </div>
          <div class="say say-02">
            <p class="p1"><span>深圳鹏程</span><span>王先生</span></p>
            <p class="p2">
              操作可视化，系统自动跟踪<br/>和提醒每票单子状态，非常<br/>的安心，省去很多时间
            </p>
          </div>
          <div class="say say-03">
            <p class="p1"><span>航嘉货运</span><span>陈先生</span></p>
            <p class="p2">
              一键订舱，自动对接船公<br/>司API接口，简化操作的<br/>工作模式，非常好
            </p>
          </div>
        </div>
      </div>

  </div>
</template>

<script>
import Carousel from "../../components/Carousel";
import MobileCarousel from "../../components/MobileCarousel";
import CardItem from "./component/CardItem";


export default {
  name: "app",
  components: {
    Carousel,
    MobileCarousel,
    CardItem,
  },
  data() {
    return {
      activeProIndex: 0,
      cardlist: [
        {
          icon: '',
          title: '报价查询',
          desc: '实时查询海量船司船代30天最低报价，支持最快，直达等多条件查询'
        }
      ]
    };
  },
  methods: {
    onSelectedProChange(i) {
      this.activeProIndex = i;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../style/mixin.scss";
$marginBottom: 50px;

.page-home {
  .area-title {
    font-weight: normal;
    color: $themeColor;
    font-size: 36px;
    text-align: center;
    margin-bottom: 30px;
  }
  .carousel-custom {
    margin-bottom: $marginBottom;
  }
  .products {
    background: linear-gradient(#fff, #fff8f3);
    .pro-list {
      display: flex;
      justify-content: space-between;
      li {
        position: relative;
        width: 200px;
        height: 200px;
        cursor: pointer;
        .text {
          position: absolute;
          bottom: 34px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 22px;
          color: #999;
          z-index: 1;
        }
        &:hover {
          .img-active {
            display: block;
          }
          .text {
            position: absolute;
            bottom: 34px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 24px;
            color: #787878;
            z-index: 2;
          }
        }
        .img-normal {
          width: 100%;
          height: 100%;
        }
        .img-active {
          position: absolute;
          display: none;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
          width: 240px;
          height: 240px;
        }
      }
    }
  }
  .pro-selected {
    height: 360px;
    position: relative;
    margin-bottom: $marginBottom;

    .pro-selected-item {
      position: absolute;
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: -1;

      &.active {
        z-index: 0;
      }
      .text {
        font-size: 24px;
        color: $themeColor;
        line-height: 52px;
      }
      img {
        max-height: 300px;
      }
    }
  }
  .why-us {
    margin-bottom: $marginBottom;
    .area-title {
      margin-bottom: 36px;
    }
    .why-us-list {
      display: flex;
    }
    .why-us-item {
      flex: 1;
      text-align: center;
      img {
        width: 160px;
        margin-bottom: 20px;
      }
      h4 {
        font-size: 20px;
        margin-bottom: 10px;
        color: #3b426b;
      }
      p {
        font-size: 16px;
        color: #999;
      }
    }
  }
  .client-say {
    background: linear-gradient(#fff8f4, #fff);
    padding: 54px 0;
    h2 {
      margin-bottom: 48px;
    }
    .client-say-content {
      position: relative;
      width: 991px;
      height: 695px;
      margin: 0 auto;
      background: url("../../../public/static/img/client_say.png") no-repeat;
      background-size: 100% 100%;
      .say {
        position: absolute;
        width: 521px;
        height: 247px;
      }
      .say-01 {
        left: -65px;
        top: 36px;
        background: url("../../../public/static/img/say_bg_02.png") no-repeat;
        background-size: 100% 100%;
        .p1 {
          position: absolute;
          left: 120px;
          top: 48px;
          font-size: 18px;
          color: #eee;
          span {
            margin-right: 14px;
          }
        }
        .p2 {
          position: absolute;
          left: 176px;
          top: 120px;
          color: #676767;
          font-size: 20px;
          span {
            margin-right: 14px;
          }
        }
      }
      .say-02 {
        right: -65px;
        top: 36px;
        background: url("../../../public/static/img/say_bg_01.png") no-repeat;
        background-size: 100% 100%;
        .p1 {
          position: absolute;
          right: 120px;
          top: 48px;
          font-size: 18px;
          color: #eee;
          span {
            margin-right: 14px;
          }
        }
        .p2 {
          position: absolute;
          left: 124px;
          top: 120px;
          color: #676767;
          font-size: 20px;
          span {
            margin-right: 14px;
          }
        }
      }
      .say-03 {
        left: 50%;
        transform: translateX(-50%);
        bottom: 100px;
        background: url("../../../public/static/img/say_bg_01.png") no-repeat;
        background-size: 100% 100%;

        .p1 {
          position: absolute;
          right: 120px;
          top: 48px;
          font-size: 18px;
          color: #eee;
          span {
            margin-right: 14px;
          }
        }
        .p2 {
          position: absolute;
          left: 124px;
          top: 120px;
          color: #676767;
          font-size: 20px;
          span {
            margin-right: 14px;
          }
        }
      }
    }
  }
}
</style>

